.task-popup{
    width: 500px;
    min-height: 350px;
}
.right {
    flex: 70%; /* Takes up 70% of the width */
    padding: 10px;
  }
  
  .left {
    flex: 30%; /* Takes up 30% of the width */
    padding: 10px;
  }
.app{
    display: flex;
}
@media (max-width: 768px) {
    .task-popup{
        width: 90%;
    }   
    .task-card{
        width: 100% !important;
    }
    .app{
        padding: 5px !important;
        display: block;
    }


}
@media (max-width: 1380px) {

    .app{
        padding: 5px !important;
        display: block;
    }


}