@import url("https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap");
body {
  font-family: 'Rubik',"DM Sans", sans-serif !important;
}
h1, h2, h3, h4, h5, h6 {
  font-family: 'Rubik',"DM Sans", sans-serif !important;
}
textarea {
  resize: none;
}
option {
  color: black;
}
